import React from 'react'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>INTEROP'19</h1>
                <h2>CARDIFF, Wales | 14/03/19</h2>
            </header>
            <div className="content">
                <p>Welcome to the <br />
                2019 Interoperability Symposium</p>
                <ul className="actions">
                    <li><a href="#one" className="button next scrolly">Find Out More</a></li>
                </ul>
            </div>
        </div>
    </section>
)
export default Banner